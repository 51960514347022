















































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

import {
  ApplicationWorkflowModel,
  ApplicationWorkflowStatus,
  ApplicationWorkflowStep,
} from "@/application-manager/models/ApplicationWorkflowModel";
import DateUtil from "@/shared/utils/DateUtil";

@Component
export default class ApplicationWorkflowInfo extends Vue {
  @Prop() applicationWorkflow!: ApplicationWorkflowModel;
  @Prop() statusColor!: Record<ApplicationWorkflowStatus, string>;

  readonly headers: Array<DataTableHeader> = [
    {
      text: this.$lang("applicationManager.applicationConfigs.jobName"),
      value: "jobName",
      sortable: false,
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.jobStarted"),
      value: "jobStarted",
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.jobEnded"),
      value: "jobEnded",
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.jobMessage"),
      value: "jobMessage",
      sortable: false,
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.jobStatus"),
      value: "jobStatus",
      sortable: false,
    },
  ];

  opened = false;

  get items(): Array<{
    jobName: string;
    jobStarted: string | null;
    jobEnded: string | null;
    jobMessage: string | null;
    jobStatus: ApplicationWorkflowStatus | null;
  }> {
    return [...this.applicationWorkflow.workflow]
      .sort(
        ({ ordinal: ordinalA }, { ordinal: ordinalB }) => ordinalA - ordinalB
      )
      .flatMap(({ steps }) => steps)
      .flat()
      .map(({ jobName, details }: ApplicationWorkflowStep) => ({
        jobName,
        jobStarted: details?.start ?? null,
        jobEnded: details?.end ?? null,
        jobMessage: details?.msg ?? null,
        jobStatus: details?.status ?? null,
      }));
  }

  getFormattedDate(date: string | null): string {
    return date ? DateUtil.formatDateTime(date) : "";
  }

  getStatusColor(status: ApplicationWorkflowStatus): string {
    return this.statusColor[status];
  }
}
