


















































import { Application, ApplicationStatus } from "@/shared/models";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ApplicationWorkflowModel,
  ApplicationWorkflow,
} from "@/application-manager/models/ApplicationWorkflowModel";

@Component
export default class ApplicationArchiveForm extends Vue {
  @Prop() value!: boolean;

  selectedApp: string | null = null;
  visible = false;

  get apps(): Array<Application> {
    const archivingApps =
      this.$store.state.applicationWorkflowStore.applicationWorkflows.flatMap(
        ({ workflowTemplate, application }: ApplicationWorkflowModel) =>
          workflowTemplate === ApplicationWorkflow.SET_APPLICATION_TO_ARCHIVED
            ? [application]
            : []
      );

    return this.$store.state.application.apps.filter(
      ({ status, id }: Application) =>
        status === ApplicationStatus.ACTIVE && !archivingApps.includes(id)
    );
  }

  get isApplicationArchiving(): boolean {
    return this.$store.state.applicationWorkflowStore.isApplicationArchiving;
  }

  async archive() {
    await this.$store.dispatch("archiveApplication", this.selectedApp);
    this.visible = false;
  }
}
