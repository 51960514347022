var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","nudge-bottom":4,"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"elevation-0",attrs:{"block":"","small":""}},'v-btn',attrs,false),on),[_c('v-progress-linear',{attrs:{"value":_vm.applicationWorkflow.completeness,"color":_vm.getStatusColor(_vm.applicationWorkflow.status)}}),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.opened ? "mdi-menu-up" : "mdi-menu-down"))])],1)]}}]),model:{value:(_vm.opened),callback:function ($$v) {_vm.opened=$$v},expression:"opened"}},[_c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.jobStarted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.jobStarted))+" ")]}},{key:"item.jobEnded",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.jobEnded))+" ")]}},{key:"item.jobStatus",fn:function(ref){
var item = ref.item;
return [(item.jobStatus)?_c('v-chip',{attrs:{"label":"","outlined":"","x-small":"","color":_vm.getStatusColor(item.jobStatus)}},[_vm._v(_vm._s(_vm.$lang( ("applicationManager.applicationConfigs.applicationConfigStatus." + (item.jobStatus.toLowerCase())) )))]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }