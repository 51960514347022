































































































































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

import ApplicationWorkflowInfo from "@/application-manager/components/ApplicationWorkflowInfo.vue";
import ApplicationWorkflowForm from "@/application-manager/components/ApplicationWorkflowForm.vue";
import ApplicationArchiveForm from "@/application-manager/components/ApplicationArchiveForm.vue";
import {
  ApplicationWorkflowModel,
  ApplicationWorkflowStatus,
  ApplicationWorkflow,
} from "@/application-manager/models/ApplicationWorkflowModel";

@Component({
  components: {
    ApplicationWorkflowInfo,
    ApplicationWorkflowForm,
    ApplicationArchiveForm,
  },
})
export default class ApplicationWorkflowsView extends Vue {
  readonly ApplicationWorkflow = ApplicationWorkflow;
  readonly headers: Array<DataTableHeader> = [
    {
      text: this.$lang("applicationManager.applicationConfigs.applicationName"),
      align: "start",
      value: "application",
      width: "18%",
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.progress"),
      align: "start",
      value: "workflow",
      sortable: false,
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.status"),
      align: "start",
      value: "status",
      width: "12%",
    },
    {
      text: this.$lang("applicationManager.applicationConfigs.actions"),
      align: "end",
      value: "actions",
      width: "8%",
    },
  ];
  readonly statusColor: Record<ApplicationWorkflowStatus, string> = {
    [ApplicationWorkflowStatus.COMPLETED]: "green",
    [ApplicationWorkflowStatus.ERROR]: "red",
    [ApplicationWorkflowStatus.IN_PROGRESS]: "blue",
  };
  readonly statuses: Array<ApplicationWorkflowStatus> = Object.values(
    ApplicationWorkflowStatus
  );
  readonly workflowTemplates = Object.values(ApplicationWorkflow);

  search = "";
  selectedStatus?: number | null = null;
  applicationFormVisible = false;
  archiveAppDialogVisible = false;
  workflow = 0;

  get isApplicationWorkflowsLoading(): boolean {
    return this.$store.state.applicationWorkflowStore
      .isApplicationWorkflowsLoading;
  }

  get applicationWorkflows(): Array<ApplicationWorkflowModel> {
    return this.$store.state.applicationWorkflowStore.applicationWorkflows.filter(
      ({ workflowTemplate, status }: ApplicationWorkflowModel) =>
        workflowTemplate === this.workflowTemplates[this.workflow] &&
        (this.selectedStatus === null ||
          this.selectedStatus === undefined ||
          status === this.statuses[this.selectedStatus])
    );
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.applicationConfigs.title")
    );
    this.$store.dispatch("fetchApplicationWorkflows");
  }

  getStatusColor(status: ApplicationWorkflowStatus): string {
    return this.statusColor[status];
  }

  rerunWorkflow({
    executionId,
    workflowTemplate,
    application,
  }: ApplicationWorkflowModel) {
    ({
      [ApplicationWorkflow.CREATE_APPLICATION]: () => {
        this.$store.commit("setApplicationWorkflowId", executionId);
        this.applicationFormVisible = true;
      },
      [ApplicationWorkflow.SET_APPLICATION_TO_ARCHIVED]: () => {
        this.$store.dispatch("updateApplicationArchiveWorkflow", {
          executionId,
          workflowTemplate,
          application,
        });
      },
    }[workflowTemplate]());
  }

  openApplicationForm() {
    this.$store.commit("setApplicationWorkflowId", null);
    this.applicationFormVisible = true;
  }
}
