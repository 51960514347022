





























import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import SidebarForm from "@/accounting-portal/components/SidebarForm.vue";
import { VSelect, VTextField } from "vuetify/lib";

import { ApplicationWorkflowRequestModel } from "@/application-manager/models/ApplicationWorkflowModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({ components: { SidebarForm, VSelect, VTextField } })
export default class ApplicationWorkflowForm extends Vue {
  @Prop() value!: boolean;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly PLATFORMS = ["ios", "android"];

  config = new ApplicationWorkflowRequestModel();

  get applicationWorkflowId(): number | null {
    return this.$store.state.applicationWorkflowStore.applicationWorkflowId;
  }

  get applicationWorkflow(): ApplicationWorkflowRequestModel | null {
    return this.$store.state.applicationWorkflowStore.applicationWorkflow;
  }

  get isApplicationWorkflowLoading(): boolean {
    return this.$store.state.applicationWorkflowStore
      .isApplicationWorkflowLoading;
  }

  get title(): string {
    return this.applicationWorkflowId
      ? this.$lang("applicationManager.applicationConfigs.relaunchTitle")
      : this.$lang("applicationManager.applicationConfigs.createTitle");
  }

  get visible(): boolean {
    return this.value;
  }

  set visible(value: boolean) {
    this.$emit("input", value);
    this.$store.commit("setApplicationWorkflow", null);
    this.config = new ApplicationWorkflowRequestModel();
  }

  get isSaving(): boolean {
    return this.$store.state.applicationWorkflowStore
      .isApplicationWorkflowSaving;
  }

  get disabledKeys(): Array<keyof ApplicationWorkflowRequestModel> {
    return this.applicationWorkflow ? ["applicationId", "applicationType"] : [];
  }

  @Watch("value")
  watchVisibility(value: boolean) {
    if (!value || !this.applicationWorkflowId) {
      return;
    }

    this.$store.dispatch("getApplicationWorkflow", this.applicationWorkflowId);
  }

  @Watch("applicationWorkflow")
  setApplicationWorkflow(
    applicationWorkflow: ApplicationWorkflowRequestModel | null
  ) {
    if (!applicationWorkflow) {
      return;
    }

    this.config = ApplicationWorkflowRequestModel.of(applicationWorkflow);
  }

  async save() {
    if (this.applicationWorkflow) {
      await this.$store.dispatch("updateApplicationWorkflow", this.config);
    } else {
      await this.$store.dispatch("createApplicationWorkflow", this.config);
    }

    this.visible = false;
  }
}
