var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("applicationManager.applicationConfigs.title"))+" ")])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$lang('components.search'),"background-color":_vm.dark ? '#1E1E1E' : 'white',"outlined":"","dense":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-caption mr-1"},[_vm._v(" "+_vm._s(_vm.$lang("applicationManager.applicationConfigs.filterByStatus"))+": ")]),_c('v-btn-toggle',{attrs:{"background-color":_vm.dark ? '#1E1E1E' : 'white'},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},_vm._l((_vm.statuses),function(status){return _c('v-btn',{key:status,attrs:{"small":"","color":_vm.dark ? '#1E1E1E' : 'white'}},[_vm._v(" "+_vm._s(_vm.$lang( ("applicationManager.applicationConfigs.applicationConfigStatus." + (status.toLowerCase())) ))+" ")])}),1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.isApplicationWorkflowsLoading,"items":_vm.applicationWorkflows,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-tabs',{staticClass:"rounded",model:{value:(_vm.workflow),callback:function ($$v) {_vm.workflow=$$v},expression:"workflow"}},_vm._l((_vm.workflowTemplates),function(workflow){return _c('v-tab',{key:workflow},[_vm._v(" "+_vm._s(_vm.$lang( ("applicationManager.applicationConfigs.workflow." + (workflow.toLowerCase())) ))+" ")])}),1),_c('div',{staticClass:"align-self-center px-4 ml-auto"},[(
                  _vm.workflowTemplates[_vm.workflow] ===
                  _vm.ApplicationWorkflow.CREATE_APPLICATION
                )?_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.openApplicationForm}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$lang( "applicationManager.applicationConfigs.createApplication" ))+" ")],1):_vm._e(),(
                  _vm.workflowTemplates[_vm.workflow] ===
                  _vm.ApplicationWorkflow.SET_APPLICATION_TO_ARCHIVED
                )?_c('application-archive-form'):_vm._e()],1)],1)]},proxy:true},{key:"item.workflow",fn:function(ref){
                var item = ref.item;
return [_c('application-workflow-info',{attrs:{"application-workflow":item,"status-color":_vm.statusColor}})]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","outlined":"","small":"","color":_vm.getStatusColor(item.status)}},[_vm._v(_vm._s(_vm.$lang( ("applicationManager.applicationConfigs.applicationConfigStatus." + (item.status.toLowerCase())) )))])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.rerunWorkflow(item)}}},on),[_c('v-icon',[_vm._v("mdi-rocket-launch")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$lang("applicationManager.applicationConfigs.rerun"))+" ")])]}}],null,true)})],1)],1),_c('application-workflow-form',{model:{value:(_vm.applicationFormVisible),callback:function ($$v) {_vm.applicationFormVisible=$$v},expression:"applicationFormVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }